import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IIntegrationAccount } from "../../../../common/services/integrations-account.service";

export interface IEditIntegrationData {
    editIntegration: IIntegrationAccount;
}

@Component({
    selector: 'app-edit-integration-dialog',
    template: require('./edit-integration-dialog.component.html')
})

export class EditIntegrationDialogComponent {
    currentIntegration!: IIntegrationAccount;
    constructor(
        public dialogRef: MatDialogRef<EditIntegrationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public editIntegrationDialogData: IEditIntegrationData
    ) {
        this.currentIntegration = {...editIntegrationDialogData.editIntegration};
    }
    cancelIntegrationNameUpdate() : void{
        this.dialogRef.close();
    }
}