import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppConstants } from "../../../../../constants/app.constants";

export interface IIntegrationsDialogData {
    youtubeAuthURL: string;
}
@Component({
    selector: 'app-add-new-integrations-dialog',
    template: require('./add-new-integrations-dialog.component.html')
})
export class AddNewIntegrationsDialogComponent {
    youtubeAuthURL: string = '';
    appConstants:any = AppConstants;

    constructor(
        public dialogRef: MatDialogRef<AddNewIntegrationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public integrationDialogData: IIntegrationsDialogData
    ) {
        this.youtubeAuthURL = integrationDialogData ? integrationDialogData.youtubeAuthURL : ''
    }
    redirectYoutube(): void {
        window.location.href = this.youtubeAuthURL;
    }
    closeAddIntegration(): void {
        this.dialogRef.close();
    }
}