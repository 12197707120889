/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { integrationsViewController } from './modules/integrations-view-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.settings', 'ngMaterial', 'ngMessages', 'comcast.directives'];
var defaultName = angular.module('comcast.settings.integrations', dependencies).component('integrationsViewController', integrationsViewController).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('integrations', {
    url: '/integrations',
    parent: 'settings',
    views: {
      'settingsContent@settings': {
        template: '<ui-view name="integrationsContent"/>'
      },
      'integrationsContent@integrations': {
        template: '<integrations-view-controller></integrations-view-controller>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };