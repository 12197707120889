import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-delete-integration-dialog',
    template: require('./delete-integration-dialog.component.html')
})
export class DeleteIntegrationDialogComponent {
    googleAccountName: string;
    constructor(
        public dialogRef: MatDialogRef<DeleteIntegrationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public deleteIntegrationDialogData: any

    ) {
        this.googleAccountName = deleteIntegrationDialogData.googleAccountName;
    }
    cancelDelteIntegration() {
        this.dialogRef.close();
    }
 }