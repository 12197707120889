declare var angular: angular.IAngularStatic;

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { downgradeComponent } from "@angular/upgrade/static";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { AppConstants } from "../../../../../constants/app.constants";
import { IIntegrationAccount, IIntegrationUpdate, IPrivateSettingReq, IntegrationsAccountService } from "../../../../common/services/integrations-account.service";
import { AddNewIntegrationsDialogComponent } from "../add-new-integrations-dialog/add-new-integrations-dialog.component";
import { EditIntegrationDialogComponent } from "../edit-integration-dialog/edit-integration-dialog.component";
import { DeleteIntegrationDialogComponent } from '../delete-integration-dialog/delete-integration-dialog.component';
import { YoutubePrivacyDialogComponent } from '../youtube-privacy-dialog/youtube-privacy-dialog.component';
import { MatSnackBar } from "@angular/material/snack-bar";
interface IPrivacySetting {
    value: string;
    viewValue: string;
}
@Component({
    selector: 'app-integrations-view',
    template: require('./integrations-view.component.html')
})

export class IntegrationsViewComponent implements OnInit, OnDestroy {
    existingIntegrations: IIntegrationAccount[] = [];
    copyOfAvailableIntegrations: IIntegrationAccount[] = [];
    totalExistingIntegrations!: number;
    youtubeAuthURL!: string;
    appConstants:any = AppConstants;
    privacySettings: IPrivacySetting[] = [
        { value: 'private', viewValue: 'Private - Not shareable by anyone other than the owner and will not appear in searches'},
        { value: 'unlisted', viewValue: 'Unlisted - Shareable by anyone who has the link, but will not appear in searches'},
        { value: 'public', viewValue: 'Public - Is fully shareable by anyone who has the link and will appear in searches'}
    ];
    updatePrivacySettingFlag: boolean = false;
    private _destroy$ = new Subject<void>();

    constructor(
        private integrationsAccountService: IntegrationsAccountService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {
    }
    ngOnInit(): void {
        this.getCurrentIntegrations();
    }
    //get current integrations
    getCurrentIntegrations() :void{
        this.integrationsAccountService.getIntegrationAccounts().pipe(takeUntil(this._destroy$)).subscribe((integrationsResponse: IIntegrationAccount[] | []) => {
            this.currrentIntegrationsAccounts(integrationsResponse);
        });
    }
    //update existing integrations 
    currrentIntegrationsAccounts(integrations: IIntegrationAccount[]) {
        this.existingIntegrations = integrations;
        this.copyOfAvailableIntegrations = JSON.parse(JSON.stringify(integrations));;
        this.totalExistingIntegrations = integrations.length;
        //single flag is being used to enable disable the privacy setting flag as its supports only one account.Infuture this has to change when we support multiple accounts
        this.updatePrivacySettingFlag = false;
    }
    //privacy setting change
    onPrivacySettingChange(currrentIntegrationsAccount: IIntegrationAccount) {
       this.copyOfAvailableIntegrations.forEach((eachIntegration: IIntegrationAccount) => {
        if(currrentIntegrationsAccount.id === eachIntegration.id && currrentIntegrationsAccount.integrationAccountName === eachIntegration.integrationAccountName && eachIntegration.privacySetting !== currrentIntegrationsAccount.privacySetting) {
            this.updatePrivacySettingFlag = true;
        } else {
            this.updatePrivacySettingFlag = false;
        }
       });
    }
    //cancel update privacy setting
    cancelUpdatePrivacySetting() {
        this.updatePrivacySettingFlag = false;
        this.existingIntegrations = this.copyOfAvailableIntegrations;
        this.copyOfAvailableIntegrations = JSON.parse(JSON.stringify(this.existingIntegrations));
    }
    //update privacy setting
    updatePrivacySetting(id: number, privacySetting: string) {
        if(id && privacySetting) {
            const privacySettingUpdate: IPrivateSettingReq = {
                id : id,
                privacySetting: privacySetting
            };
            const confirmYoutubePrivacyDialogRef = this.dialog.open(YoutubePrivacyDialogComponent, {
                width: '350px',
                data: privacySettingUpdate
            });
            confirmYoutubePrivacyDialogRef.afterClosed().subscribe((res:boolean) => {
                if(res) {
                    this.integrationsAccountService.updatePrivacySetting(privacySettingUpdate).pipe(takeUntil(this._destroy$)).subscribe((res: IIntegrationAccount[]) => {
                        this.currrentIntegrationsAccounts(res);
                        this._snackBar.open('Privacy setting has been updated' , '', {
                            duration: 3000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top'
                        });
                    }, (error => {
                        this._snackBar.open('Privacy setting failed to update' , '', {
                            duration: 3000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top'
                        });
                    }));
                } else {
                    this.cancelUpdatePrivacySetting();
                }
            });
        }
    }
    //open modal to add new integrations
    addNewIntegrations() : void {
        this.getYoutubeAuthUrl();
    }
    //get youtube auth url
    getYoutubeAuthUrl() {
        let locationPath:string = window.location.href;
        this.integrationsAccountService.getYoutubeAuthURL(locationPath).pipe(takeUntil(this._destroy$)).subscribe((res: string) => {
            this.youtubeAuthURL = res;
            this.dialog.open(AddNewIntegrationsDialogComponent, {
                width: '560px',
                data: {
                    youtubeAuthURL : this.youtubeAuthURL
                }
            });
        });
    }
    //dialog for edit integration name
    editIntegration(integration: IIntegrationAccount) {
        const editDialogRef = this.dialog.open(EditIntegrationDialogComponent, {
            width: '250px',
            data: {
                editIntegration : integration
            }
        })
        editDialogRef.afterClosed().subscribe((result:IIntegrationAccount) => {
            if(result) {
                const integrationUpdate: IIntegrationUpdate = {
                    id : result.id,
                    name: result.userIntegrationName
                };
                this.integrationsAccountService.updateIntegrationAccount(integrationUpdate).pipe(
                    takeUntil(this._destroy$),
                    switchMap(()=> 
                    this.integrationsAccountService.getIntegrationAccounts()
                )).subscribe((res) => {
                    this.existingIntegrations = res;
                    this._snackBar.open('Integration account name has been updated' , '', {
                        duration: 3000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top'
                    });
                }, (error) => {
                    this._snackBar.open('Integration account name failed to update' , '', {
                        duration: 3000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top'
                    });
                });
            }
        });
    }
    //delete integration account
    deleteIntegrationAccount(integration: IIntegrationAccount) {
        const deleteDialogRef = this.dialog.open(DeleteIntegrationDialogComponent, {
            width: '300px',
            data: {
                googleAccountName: integration.integrationAccountName
            }
        });
        deleteDialogRef.afterClosed().subscribe((googleAccountName: string) => {
            if(googleAccountName) {
                this.integrationsAccountService.deleteIntegrationAccount(googleAccountName).pipe(
                    takeUntil(this._destroy$),
                    switchMap(()=> 
                    this.integrationsAccountService.getIntegrationAccounts()
                )).subscribe((res) => {
                    this.existingIntegrations = res;
                    this.totalExistingIntegrations = res.length;
                    this._snackBar.open(`${googleAccountName} Account has been deleted` , '', {
                        duration: 3000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top'
                    });
                }, (error) => {
                    this._snackBar.open(`${googleAccountName} Account delete has been failed` , '', {
                        duration: 3000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top'
                    });
                });
            }
        });
    }
    ngOnDestroy() :void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
angular.module("comcast.directives").directive(
    'appIntegrationsView',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: IntegrationsViewComponent, inputs: [], outputs: []}));