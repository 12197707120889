import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from "@angular/material/snack-bar";


import { IntegrationsViewComponent } from '../components/integrations-view/integrations-view.component';
import { AddNewIntegrationsDialogComponent } from "../components/add-new-integrations-dialog/add-new-integrations-dialog.component";
import { EditIntegrationDialogComponent } from '../components/edit-integration-dialog/edit-integration-dialog.component';
import { DeleteIntegrationDialogComponent } from '../components/delete-integration-dialog/delete-integration-dialog.component';
import { YoutubePrivacyDialogComponent } from "../components/youtube-privacy-dialog/youtube-privacy-dialog.component";
@NgModule({
    entryComponents: [
        IntegrationsViewComponent,
        AddNewIntegrationsDialogComponent,
        EditIntegrationDialogComponent,
        DeleteIntegrationDialogComponent,
        YoutubePrivacyDialogComponent
    ],
    declarations: [
        IntegrationsViewComponent,
        AddNewIntegrationsDialogComponent,
        EditIntegrationDialogComponent,
        DeleteIntegrationDialogComponent,
        YoutubePrivacyDialogComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSnackBarModule
    ]
})
export class IntegrationsViewModule { }