import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IPrivateSettingReq } from "../../../../common/services/integrations-account.service";

@Component({
    selector: 'youtube-privacy-dialog',
    template: require('./youtube-privacy-dialog.component.html')
})

export class YoutubePrivacyDialogComponent { 
    privacySettings!:IPrivateSettingReq;
    constructor(
        @Inject(MAT_DIALOG_DATA) public youtubePrivacyDialogData: IPrivateSettingReq,
    ) {
        this.privacySettings = this.youtubePrivacyDialogData;
    }
    ngOnInit() {

    }
}
